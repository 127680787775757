<template>
  <div class="quick">
    <el-table
      :data="tableData"
      :indent="indents"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <!-- 版本 -->
      <el-table-column
        prop="versionnumber"
        :label="$t('label.trigger.triggerVersion')"
        width="180"
      >
        <template slot-scope="scope">
          <a
            :href="`/#/commonObjects/detail/${scope.row.articleid}/DETAIL`"
            style="text-decoration: none; color: #006dcc; cursor: pointer"
            >{{ scope.row.versionnumber }}</a
          ><a
            v-show="scope.row.articleid == id"
            style="text-decoration: none; color: #006dcc; cursor: pointer"
            >({{ $t("label.emailtocloudcc.button.view") }})</a
          >
          <!-- 查看 -->
        </template>
      </el-table-column>
      <!-- 修改人 -->
      <el-table-column
        prop="lastmodifybyname"
        :label="$t('label.knowledgebase.modifiedby')"
        width="180"
      >
        <template slot-scope="scope">
          <a
            :href="`/#/commonObjects/detail/${scope.row.lastmodifybyid}/DETAIL`"
            style="text-decoration: none; color: #006dcc; cursor: pointer"
            >{{ scope.row.lastmodifybyname }}</a
          >
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column
        prop="operateDescription"
        :label="$t('label.import.index.operation')"
      >
      </el-table-column>
      <!-- 修改日期 -->
      <el-table-column
        prop="lastmodifydate"
        :label="$t('label.knowledgebase.modification.date')"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/**
 * @description: 查看文章的历史版本信息
 *
 * 功能：
 * 1、根据文章ID查询历史版本信息。
 * 逻辑：
 * 1、根据传入的文章ID，查询历史版本信息。
 */

import * as knowledgearticlesApi from "./api.js";
export default {
  name: "quickBooks",
  props: {
    /**
     * 文章的ID号
     */
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    // 这里存放数据
    return {
      indents: 0, // 控制表格缩进
      tableData: [], // 表格数据
    };
  },

  methods: {
    /**
     * 知识文章 版本列表
     */
    queryArticleVersionList() {
      let params = {
        id: this.id,
      };
      knowledgearticlesApi.queryArticleVersionList(params).then((res) => {
        this.tableData = res.data;
      });
    },
  },
  mounted() {
    this.queryArticleVersionList();
  },
};
</script>
